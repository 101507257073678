import React from 'react';
import './index.scss';

export default function Label({ text }) {
  return <span className="label">{text}</span>;
}

Label.defaultProps = {
  text: 'Label'
};
