import React, { useState } from "react";
import axios from "axios";
import { Button } from "semantic-ui-react";

export default function GiftItem({
  remoteCode,
  remoteCodeClaimed,
}) {
  const [accepted, setAccepted] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [error, setError] = useState(null);
  const [imOpen, setImOpen] = useState(false);

  function onAcceptClick() {
    axios
      .get(`/substitute-request-notification-remote-code-accept/${remoteCode}/`)
      .then(() => {
        setAccepted(true);
      })
      .catch((err) => {
        setError(err.response.data.ui_message);
      });
  }

  function onDeclineClick() {
    axios
      .get(
        `/substitute-request-notification-remote-code-decline/${remoteCode}/`
      )
      .then(() => setRejected(true))
      .catch((err) => {
        setError(err.response.data.ui_message);
      });
  }

  if (remoteCodeClaimed) {
    return (
      <div className="sub-request">
        <p style={{ textAlign: "center" }}>
          Looks like you've already clicked on this link.
        </p>
      </div>
    );
  }

  return (
    <div className="sub-request">
      <div className="meta">
        <h1>Gift Card</h1>
        <p>
        </p>
      </div>
      <div style={{ marginBottom: "2em", textAlign: "center" }}>
        {error && (
          <p style={{ marginTop: "1em" }}>
            <b>{error}</b>
          </p>
        )}
      </div>
    </div>
  );
}

GiftItem.defaultProps = {
  remoteCodeClaimed: false,
};
