import React, { Component } from "react";
import ReactTable from "react-table";
import axios from "axios";
import PropTypes from "prop-types";

class PaginatedTable extends Component {
  state = {
    loading: true,
    pages: null,
    tableData: [],
    total: 0,
  };

  table = React.createRef();

  componentDidMount = () => {
    if (this.props.filterButtons.length) {
      let addedState = {};
      this.props.filterButtons
        .map((f) => ({
          key: f.name,
          value: f.default,
        }))
        .forEach((f) => (addedState[f.key] = f.value));

      this.setState({ ...addedState });
    }
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.filter !== this.props.filter ||
      (prevProps.refresh !== this.props.refresh && this.props.refresh)
    ) {
      this.onFetchData(this.table.current.state, null, this.props.filter);
    }

    if (
      JSON.stringify(prevProps.additionalPostData) !==
      JSON.stringify(this.props.additionalPostData)
    ) {
      this.onFetchData(this.table.current.state, null);
    }
  };

  onFilterButtonClick = (filterKey, filterValue) => {
    this.setState({ [filterKey]: filterValue }, () => {
      this.onFetchData(this.table.current.state, null);
    });
  };

  onFetchData = (state, instance) => {
    const { page, pageSize, sorted, filtered } = state;

    if (filtered.length) {
      filtered.forEach((filter) => {
        const filterOption = this.props.filterOptions.find(
          (f) => f.id === filter.id
        );
        if (!filterOption) return;
        filter.object = filterOption.object;
      });
    }

    this.setState({ loading: true });

    let postData = {
      page: page * pageSize,
      page_size: pageSize,
      sorted,
      filtered,
      // filtered,
    };

    if (this.props.filterButtons.length) {
      this.props.filterButtons.forEach((f) => {
        postData[f.name] = this.state[f.name] || f.default;
      });
    }

    if (this.props.additionalPostData) {
      postData = {
        ...postData,
        ...this.props.additionalPostData,
      };
    }

    if (this.axiosCancelSource) {
      this.axiosCancelSource.cancel("Cancelled");
    }

    this.axiosCancelSource = axios.CancelToken.source();

    axios
      .post(this.props.url, postData, {
        cancelToken: this.axiosCancelSource.token,
      })
      .then(({ data }) => {
        let pages = 0;
        if (data.total < pageSize) {
          pages = 1;
        } else {
          pages = Math.ceil(data.total / pageSize);
        }

        this.setState({
          loading: false,
          pages,
          tableData: data.data,
          total: data.total ? data.total : 0,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    let columns = [...this.props.columns];
    const { filterButtons } = this.props;

    if (!columns.length) {
      return <p>No Columns...</p>;
    }

    return (
      <div>
        <ReactTable
          className="-ui -striped -table"
          columns={columns}
          data={this.state.tableData}
          defaultPageSize={this.props.defaultPageSize}
          defaultSorted={this.props.defaultSorted}
          loading={this.state.loading}
          loadingText={`Loading ${this.props.entityName}`}
          manual
          minRows={5}
          multiSort={false}
          noDataText={`No ${this.props.entityName} Available`}
          onFetchData={this.onFetchData}
          pages={this.state.pages}
          ref={this.table}
          rowsText={`${this.props.entityName}`}
        />
      </div>
    );
  }
}

PaginatedTable.defaultProps = {
  additionalPostData: null,
  columns: [],
  defaultPageSize: 10,
  defaultSorted: [],
  filterButtons: [],
  refresh: false,
};

PaginatedTable.propTypes = {
  columns: PropTypes.array,
  defaultPageSize: PropTypes.number,
  defaultSorted: PropTypes.array,
  entityName: PropTypes.string,
  filter: PropTypes.number,
  filterButtons: PropTypes.array,
  filterOptions: PropTypes.array,
  keyName: PropTypes.string,
  refresh: PropTypes.bool,
  url: PropTypes.string,
};

export default PaginatedTable;
