export const SET_CLIENT = "SET_CLIENT";
export const UPDATE_CLIENT = "UPDATE_CLIENT";

function clientReducer(state, action) {
  switch (action.type) {
    case SET_CLIENT:
      return {
        ...state,
        client: {
          ...action.payload,
          paymentProfileId: action.payload.customer_payment_profile || null,
        },
      };
    case UPDATE_CLIENT:
      return {
        ...state,
        client: {
          ...state.client,
          ...action.payload,
        },
      };
    default:
      return state;
  }

  return state;
}

export default clientReducer;
