import React, { Component } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { Form as SemanticForm } from "semantic-ui-react";
import Fade from "react-reveal/Fade";

import Message from "@components/Messages";

import { Button } from "@components/shared";
import CustomInputComponent from "./CustomInputComponent";

export default function BasicForm(props) {
  let initialValues = {};
  let schemas = {};

  if (!props.fields.length) {
    return <p>No fields.</p>;
  }

  props.fields.forEach((f) => {
    if (f.grouped) {
      f.fields.forEach((field) => {
        initialValues[field.name] = field.initialValue;
        schemas[field.name] = field.schema();
      });
    } else {
      initialValues[f.name] = f.initialValue;
      schemas[f.name] = f.schema();
    }
  });

  const schema = Yup.object().shape(schemas);

  const { loading } = props;

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={(values, { resetForm }) => {
          props.onSubmit(values);
          // resetForm();
        }}
        enableReinitialize
      >
        {({ errors, touched }) => (
          <Form
            // className={`ui form ${loading ? 'loading' : ''}`}
            className="ui form"
          >
            {props.fields.map((f, i) => {
              if (f.grouped) {
                return (
                  <SemanticForm.Group widths="equal" key={i}>
                    {f.fields.map((fi, ii) => (
                      <Field
                        component={CustomInputComponent}
                        datepicker={fi.datepicker}
                        key={ii}
                        fluid
                        label={fi.label}
                        maxDate={fi.maxDate}
                        minDate={fi.minDate}
                        numberFormat={fi.numberFormat}
                        name={fi.name}
                        options={fi.options}
                        radio={fi.radio}
                        required={fi.required}
                        select={fi.select}
                        search={fi.search}
                        textArea={fi.textArea}
                        tinyMce={fi.tinyMce}
                      />
                    ))}
                  </SemanticForm.Group>
                );
              }

              return (
                <Field
                  component={CustomInputComponent}
                  datepicker={f.datepicker}
                  key={i}
                  label={f.label}
                  maxDate={f.maxDate}
                  minDate={f.minDate}
                  name={f.name}
                  numberFormat={f.numberFormat}
                  options={f.options}
                  radio={f.radio}
                  required={f.required}
                  select={f.select}
                  search={f.search}
                  textArea={f.textArea}
                  tinyMce={f.tinyMce}
                />
              );
            })}
            {props.error && (
              <Fade bottom>
                <Message negative text={props.error} />
              </Fade>
            )}
            <div className="form-actions">
              {props.children}
              <Button.Submit
                disabled={props.submitDisabled}
                loading={props.loading}
                text={props.buttonText}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

BasicForm.defaultProps = {
  buttonText: "Save",
  fields: [],
  submitDisabled: false,
};
