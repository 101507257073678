import React from 'react';
import './index.scss';

export default function Card({ title, content, icon }) {
  return (
    <div className="card">
      <div className="card-text">
        <h2>{content}</h2>
        <small>{title}</small>
      </div>
      {icon && icon}
    </div>
  );
}

Card.defaultProps = {
  content: '',
  title: '',
  icon: null
};
