import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Segment } from 'semantic-ui-react';

const SegmentMessage = ({ color, icon, header, text }) => {
  return (
    <Segment padded textAlign="center">
      <Icon name={icon} size="massive" color={color} />
      <h2>{header}</h2>
      <p style={{ maxWidth: '50%', margin: '0 auto' }}>{text}</p>
    </Segment>
  );
};

SegmentMessage.defaultProps = {
  text: '',
};

SegmentMessage.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  header: PropTypes.string,
  text: PropTypes.string,
};

export default SegmentMessage;