import React, { useState } from 'react';
import { FiMoreVertical } from 'react-icons/fi';

export default function TableMoreButton(props) {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="table-more">
      <button
        onClick={() => setOpen(!isOpen)}
        className="table-more-button"
      >
        <FiMoreVertical />
      </button>
      {
        isOpen &&
          <div className="table-more-panel">
            {props.children}
          </div>
      }
    </div>
  );
}