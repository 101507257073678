import React from 'react';

export default function ErrorText({ text }) {
  if (!text) return null;

  return <p className="error-text">{text}</p>;
}

ErrorText.defaultProps = {
  text: ''
};
