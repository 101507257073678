import React, { useState, useEffect } from "react";
import axios from "axios";
import { Loader } from "semantic-ui-react";
import GiftItem from "./GiftItem";
import { BigError } from "@components/shared";
import { bdayvipFullLogo } from "@assets/images";
import "./index.scss";

export default function Code({ remoteCode }) {
  const [codeData, setCodeData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  if (!remoteCode) return null;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);
    axios
      .get(`/verify-code/${remoteCode}/`)
      .then(({ data }) => {
        setLoading(false);
        setCodeData(data);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.ui_message);
      });
  }

  if (loading) {
    return <Loader content="Loading..." active size="huge" />;
  }

  if (error) {
    return (
      <div className="code-view">
        <BigError message={error} showHeader={false} />
      </div>
    );
  }

  return (
    <div className="code-view">
      <div className="wrapper">
        <header>
          <img src={bdayvipFullLogo} alt="BdayVIP" className="logo" />
        </header>
        {codeData && (
          <React.Fragment>
            {codeData.substitute_request_notification && (
              <GiftItem
                remoteCode={remoteCode}
                remoteCodeClaimed={codeData.claimed}
              />
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

Code.defaultProps = {
  remoteCode: "",
};
