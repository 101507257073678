import React from 'react';
import { AiFillAlert } from 'react-icons/ai';

export default function ViewError({ text }) {
  return (
    <div className="view-error">
      <AiFillAlert />
      <h1>Uh Oh!</h1>
      <p>{text}</p>
    </div>
  );
}

ViewError.defaultProps = {
  text: 'Try again later'
};
