import React from "react";
import styled from "styled-components";

const Button = styled.button`
  outline: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background: whitesmoke;
  padding: 8px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  color: inherit;
`;

export default function BasicButton({ style, onClick, disabled, text }) {
  return (
    <Button style={style} onClick={onClick} disabled={disabled}>
      {text}
    </Button>
  );
}

BasicButton.defaultProps = {
  disabled: false,
  text: "Basic",
  style: {},
};
