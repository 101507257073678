import React from "react";
import DatePicker from "react-datepicker";
import { Editor } from "@tinymce/tinymce-react";
import NumberFormat from "react-number-format";
// import moment from "moment";
// import "moment-timezone";
// import { dateToUTC } from "@util/dateToUTC";
// import { toDate, format, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { Form as SemanticForm, Label } from "semantic-ui-react";
import { Formik, Form, Field } from "formik";

const CustomInputComponent = ({
  field,
  timezone,
  form: { touched, errors },
  ...props
}) => {
  if (props.radio) {
    return (
      <SemanticForm.Field>
        <label style={{ marginBottom: "10px" }}>{props.label}</label>
        <SemanticForm.Radio
          label={field.value ? "Yes" : "No"}
          name={field.name}
          toggle
          checked={field.value}
          onChange={(e, d) =>
            field.onChange({
              target: {
                name: d.name,
                value: d.checked,
              },
            })
          }
        />
      </SemanticForm.Field>
    );
  }
  if (props.select) {
    return (
      <SemanticForm.Field>
        <SemanticForm.Select
          error={errors[field.name] && touched[field.name]}
          value={field.value}
          label={props.label}
          onBlur={(e, d) =>
            field.onBlur({
              target: {
                name: d.name,
                value: d.value,
              },
            })
          }
          onChange={(e, d) =>
            field.onChange({
              target: {
                name: d.name,
                value: d.value,
              },
            })
          }
          options={props.options}
          placeholder="Select one..."
          name={field.name}
          search={props.search ? true : false}
        />
        {touched[field.name] && errors[field.name] ? (
          <Label
            basic
            //color="red"
            style={{ marginTop: "0.25em" }}
            pointing
          >
            {errors[field.name]}
          </Label>
        ) : null}
      </SemanticForm.Field>
    );
  }

  if (props.tinyMce) {
    return (
      <SemanticForm.Field className={props.required ? "required" : ""}>
        <label
          className={
            touched[field.name] && errors[field.name] ? "mde-error-label" : ""
          }
        >
          {props.label} {!props.required && ` (optional)`}
        </label>
        <Editor
          apiKey={"tyc0jhncxwzd9bxpb9wwlxb29jt381nl0e83q127szh64ybu"}
          init={{
            height: 350,
            plugins: ["lists", "hr"],
            menubar: false,
            block_formats:
              "Paragraph=p; Heading 2=h2; Heading 3=h3; Heading 4=h4; Preformatted=pre",
            toolbar: [
              "undo redo cut copy paste | bold italic underline | \
          alignleft aligncenter alignright alignjustify | \
          removeformat | help",
            ],
            // color_map: [
            //   "000000",
            //   "Black",
            //   "808080",
            //   "Gray",
            //   "f1c40f",
            //   "Say To Get Yellow",
            //   "0cb0f8",
            //   "Do To Get Blue",
            //   "fa0cde",
            //   "Do To Give Pink",
            //   "008e6e",
            //   "PowerCore Green",
            //   "3c2e7e",
            //   "PowerCore Purple",
            //   "126afb",
            //   "Blue Card",
            //   "9840fb",
            //   "Purple Card",
            //   "fc0d1b",
            //   "Red Card",
            //   "fd9928",
            //   "Peach Card",
            //   "0a640c",
            //   "Green Card",
            //   "ffd900",
            //   "Gold Card",
            //   "969696",
            //   "Platinum Card",
            // ],
          }}
          // initialValue={field.value}
          onEditorChange={(e) =>
            field.onChange({
              target: { name: field.name, value: e },
            })
          }
          value={field.value}
          className={
            touched[field.name] && errors[field.name] ? "mde-error" : ""
          }
        />
        {/* <ReactMde
          className={
            touched[field.name] && errors[field.name] ? "mde-error" : ""
          }
          onChange={(value) =>
            field.onChange({
              target: { name: field.name, value },
            })
          }
          onTabChange={props.handleTabChange}
          value={field.value}
          generateMarkdownPreview={(md) =>
            Promise.resolve(props.converter.makeHtml(md))
          }
          minEditorHeight={300}
          selectedTab={props.tab}
        /> */}
        {touched[field.name] && errors[field.name] && (
          <Label basic color="red" style={{ marginTop: "0.95em" }} pointing>
            {errors[field.name]}
          </Label>
        )}
      </SemanticForm.Field>
    );
  }

  if (props.textArea) {
    return (
      <SemanticForm.Field required>
        <SemanticForm.TextArea
          name={field.name}
          onBlur={field.onBlur}
          onChange={field.onChange}
          label={`${props.label}${!props.required ? " (optional)" : ""}`}
          value={field.value}
          error={errors[field.name] && touched[field.name]}
        />
        {props.showCount && (
          <CharacterCounter
            maxLength={props.maxLength}
            valueLength={
              field.value.replace(
                /<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g,
                ""
              ).length
            }
          />
        )}
        {touched[field.name] && errors[field.name] ? (
          <Label basic style={{ marginTop: "0.25em" }} pointing>
            {errors[field.name]}
          </Label>
        ) : null}
      </SemanticForm.Field>
    );
  }

  if (props.datepicker) {
    if (props.withTime) {
      return (
        <SemanticForm.Field>
          <label>
            {props.label}
            {""}
            {field.value ? (
              <React.Fragment>
                : <FormattedDate date={field.value} withTime />
              </React.Fragment>
            ) : (
              ""
            )}
          </label>
          <DatePicker
            // minDate={props.minDate ? props.minDate : null}
            minDate={
              props.minDate
                ? props.minDate
                : props.dependsOn
                ? props.values[props.dependsOn]
                : null
            }
            onChange={(dateObj, t) => {
              field.onChange({
                target: {
                  name: field.name,
                  // value: zonedTimeToUtc(dateObj, timezone),
                  value: dateObj,
                },
              });
            }}
            name={field.name}
            placeholderText="Select a date..."
            selected={
              field.value
                ? // ? utcToZonedTime(new Date(moment.utc(field.value)), timezone)
                  new Date(field.value)
                : null
            }
            showYearDropdown
            dropdownMode="select"
            // selected={field.value ? field.value : null}
            showTimeSelect={props.withTime ? true : false}
            timeIntervals={15}
            timeFormat="h:mm aa"
            timeCaption="time"
            dateFormat="MMMM d, yyyy h:mm aa"
            autoComplete="off"
          />
          {props.customError && (
            <Label basic style={{ marginTop: "0.25em" }} pointing>
              {props.customError}
            </Label>
          )}
          {touched[field.name] && errors[field.name] ? (
            <Label basic color="red" style={{ marginTop: "0.25em" }}>
              {errors[field.name]}
            </Label>
          ) : null}
        </SemanticForm.Field>
      );
    }

    return (
      <SemanticForm.Field>
        <label>{props.label}</label>
        <DatePicker
          minDate={props.minDate ? props.minDate : null}
          // onChange={(dateObj, t) =>
          //   field.onChange({
          //     target: { name: field.name, value: dateObj },
          //   })
          // }
          onChange={(dateObj, t) => {
            field.onChange({
              target: {
                name: field.name,
                // value: zonedTimeToUtc(dateObj, timezone),
                value: dateObj,
              },
            });
          }}
          selected={
            field.value
              ? // ? utcToZonedTime(new Date(moment.utc(field.value)), timezone)
                new Date(field.value)
              : null
          }
          filterDate={props.dateFilter || null}
          name={field.name}
          placeholderText="Select a date..."
          // selected={field.value ? field.value : null}
          showYearDropdown
          dropdownMode="select"
          autoComplete="off"
        />
        {props.customError && (
          <Label basic style={{ marginTop: "0.25em" }} pointing>
            {props.customError}
          </Label>
        )}
        {touched[field.name] && errors[field.name] ? (
          <Label basic color="red" style={{ marginTop: "0.25em" }}>
            {errors[field.name]}
          </Label>
        ) : null}
      </SemanticForm.Field>
    );
  }

  if (props.numberFormat) {
    return (
      <SemanticForm.Field>
        <label>
          {props.label}
          {!props.required && " (optional)"}
        </label>
        <NumberFormat
          {...props.numberFormat}
          value={field.value}
          onValueChange={(e) =>
            field.onChange({
              target: { name: field.name, value: e.formattedValue },
            })
          }
          // onBlur={(e) => console.log(e)}
          name={field.name}
        />
        {/* <SemanticForm.Input
          name={field.name}
          type="text"
          fluid
          onBlur={field.onBlur}
          onChange={field.onChange}
          label={`${props.label}${!props.required ? " (optional)" : ""}`}
          value={field.value}
          error={errors[field.name] && touched[field.name]}
        /> */}
        {touched[field.name] && errors[field.name] && (
          <Label
            basic
            // color="red"
            style={{ marginTop: "0.25em" }}
            pointing
          >
            {errors[field.name]}
          </Label>
        )}
      </SemanticForm.Field>
    );
  }

  return (
    <SemanticForm.Field required>
      <SemanticForm.Input
        name={field.name}
        type="text"
        fluid
        onBlur={field.onBlur}
        onChange={field.onChange}
        label={`${props.label}${!props.required ? " (optional)" : ""}`}
        value={field.value}
        error={errors[field.name] && touched[field.name]}
      />
      {touched[field.name] && errors[field.name] ? (
        <Label
          basic
          color="red"
          style={{ marginTop: "0.25em" }}
          pointing
        >
          {errors[field.name]}
        </Label>
      ) : null}
    </SemanticForm.Field>
  );
};

export default CustomInputComponent;
