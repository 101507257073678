import React from "react";
import * as Showdown from "showdown";

const converter = new Showdown.Converter();

const TinyMceBody = ({ body, style }) => {
  return (
    <div
      style={style}
      dangerouslySetInnerHTML={{ __html: converter.makeHtml(body) }}
    />
  );
};

TinyMceBody.defaultProps = {
  body: "",
  style: {},
};

export default TinyMceBody;
