import React from "react";
import { Icon } from "semantic-ui-react";
import PropTypes from "prop-types";

import { bdayvipFullLogo } from "@assets/images";

export const BigError = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        fontSize: "1.25em",
      }}
    >
      <img
        src={bdayvipFullLogo}
        style={{ width: "300px", display: "block", margin: "0 auto 2em auto" }}
      />
      {props.showHeader && <h1>There's a problem</h1>}
      <p>{props.message}</p>
      {props.children}
    </div>
  );
};

BigError.defaultProps = {
  message: "Something went wrong...try again later",
  showHeader: true,
};

BigError.propTypes = {
  message: PropTypes.string,
};
