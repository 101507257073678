import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";

import { SET_ADMIN } from "@duck/admin";
import { SET_CLIENT } from "@duck/client";

// import { Admin, Auth, Client } from "./containers/";

const Admin = React.lazy(() => import("./containers/Admin"));
const Auth = React.lazy(() => import("./containers/Auth"));
const Client = React.lazy(() => import("./containers/Client"));

import queryString from "query-string";
import Code from "@components/Code";

class BdayvipApp extends Component {
  state = {
    error: null,
    loading: true,
  };

  componentDidMount() {
    axios
      .get("/status/")
      .then(({ data }) => {
        if (data.admin) {
          return this.props.dispatch({
            type: SET_ADMIN,
            payload: data.admin,
          });
        }

        if (data.client) {
          return this.props.dispatch({
            type: SET_CLIENT,
            payload: data.client,
          });
        }
      })
      .then(() => {
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false, error: "Status Error" });
      });
  }

  render() {
    const { error, loading } = this.state;
    const { admin, client } = this.props;
    const queryParams = queryString.parseUrl(window.location.href);

    if (queryParams.query && queryParams.query.code) {
      return <Code remoteCode={queryParams.query.code} />;
    }

    if (error) {
      return <Auth error={true} />;
    }

    if (loading) {
      return <div></div>;
    }

    if (!loading && !admin && !client) {
      return (
        <React.Suspense fallback={<div></div>}>
          <Auth />
        </React.Suspense>
      );
    }

    if (admin) {
      return (
        <React.Suspense fallback={<div></div>}>
          <Admin />
        </React.Suspense>
      );
    }

    if (client) {
      return (
        <React.Suspense fallback={<div></div>}>
          <Client getStartedStatus={client.get_started_status} />
        </React.Suspense>
      );
    }

    return <div></div>;
  }
}

function mapStateToProps(state) {
  return {
    admin: state.admin,
    client: state.client,
  };
}
export default connect(mapStateToProps)(BdayvipApp);
