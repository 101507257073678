import React from 'react';
import { FaPlusCircle } from 'react-icons/fa';

export default function ToggleButton({ open, onClick }) {
  return (
    <button className={`toggle-button${open ? ' open' : ''}`} onClick={onClick}>
      <FaPlusCircle />
    </button>
  );
}

ToggleButton.defaultProps = {
  open: false
};
