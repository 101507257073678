import React from 'react';
import Loader from 'react-loader-spinner'

const loaderConfig = {
  type: 'ThreeDots',
  height: 15,
  width: 15,
  color: '#fff',
}

export default function Button(props) {
  return (
    <button
      type={props.type}
      className="button submit"
      disabled={props.disabled !== undefined ? props.disabled : props.loading}
      onClick={(e) => props.onClick ? props.onClick(e) : null}
    >
      {
        props.loading
          ? <Loader {...loaderConfig} />
          : props.text
      }
    </button>
  );
}

Button.defaultProps = {
  loading: false,
  text: 'Submit',
  type: 'button',
}
