import React from 'react';
export default function IconButton({ icon, text, onClick, title }) {
  return (
    <button onClick={onClick} title={title}>
      {icon} {text && <span>{text}</span>}
      <style jsx>{`
        span {
          color: #000;
          margin-left: 12px;
        }

        button {
          padding: 0;
          background: none;
          outline: none;
          text-align: left;
          cursor: pointer;
          border: none;
          justify-content: flex-start;
        }
      `}</style>
    </button>
  );
}

IconButton.defaultProps = {
  title: '',
  icon: 'phone',
  text: ''
};
