import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";

export default function BackButton({ text, url, style, showIcon }) {
  return (
    <div style={{ ...style }} className="back-btn">
      <Link to={url}>
        {showIcon && (
          <Icon name="circle arrow left" style={{ color: "#0f53ab" }} />
        )}{" "}
        {text}
      </Link>
    </div>
  );
}

BackButton.defaultProps = {
  // what the text says
  text: "",
  // where to go
  url: "/",
  // should the icon show
  showIcon: true,
  // additional style
  style: {},
};
