export const SET_LOADING = 'SET_LOADING';

function loadingReducer(state, action) {
  switch (action.type) {
  case SET_LOADING:
    return {
      ...state,
      loading: action.payload,
    };
  default:
    return state;
  }

  return state;
}

export default loadingReducer;
