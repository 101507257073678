import React from 'react';

export default function NoMatch(props) {
  return (
    <div
      style={{
        margin: '50px auto',
        width: '500px',
        textAlign: 'center',
      }}
    >
      <h1 style={{ fontSize: '80px' }}>{props.text}</h1>
      <p>We couldn't find what you're looking for.</p>
    </div>
  );
}

NoMatch.defaultProps = {
  text: '404'
}
