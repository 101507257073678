import React from "react";
import styled from "styled-components";

const Button = styled.button`
  outline: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background: ${(props) =>
    props.active ? "var(--buttonBackgroundColor)" : "whitesmoke"};
  padding: 8px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  color: ${(props) => (props.active ? "#fff" : "inherit")};
  font-weight: bold;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

export default function ActiveButton({
  style,
  onClick,
  disabled,
  active,
  text,
}) {
  return (
    <Button style={style} onClick={onClick} disabled={disabled} active={active}>
      {text}
    </Button>
  );
}

ActiveButton.defaultProps = {
  active: false,
  disabled: false,
  text: "Basic",
  style: {},
};
