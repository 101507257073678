import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';

export default function Warning({ text, align }) {
  return (
    <p className="warning" style={{ justifyContent: align }}>
      <FaExclamationTriangle /> {text}
    </p>
  );
}

Warning.defaultProps = {
  text: 'Default Warning',
  align: 'flex-start'
};
