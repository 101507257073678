import React, { Component } from 'react';
import ReactTable from 'react-table';
import axios from 'axios';
import PropTypes from 'prop-types';

class BasicTable extends Component {
  state = {
    loading: true,
    tableData: []
  };

  componentDidMount = () => {
    if (!this.props.fetch) {
      this.setState({
        loading: false,
        tableData: this.props.data
      });
    } else {
      this.fetchData();
    }
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.data !== this.props.data) {
      this.setState({
        tableData: this.props.data
      });
    }

    if (prevProps.url !== this.props.url) {
      this.fetchData();
    }

    if (prevProps.refresh !== this.props.refresh) {
      this.fetchData();
    }
  };

  fetchData = () => {
    if (!this.props.url) return this.setState({ loading: false });

    axios
      .get(this.props.url)
      .then(({ data }) => {
        let tableData = [...data.response];

        if (this.props.keyName) {
          tableData = data.response[0][this.props.keyName];
        }

        this.setState({
          loading: false,
          tableData: tableData
        });

        return tableData;
      })
      .then((d) => {
        if (this.props.returnData) {
          this.props.returnData(d);
        }
      })
      .catch(() => {
        this.setState({
          loading: false
        });
      });
  };

  render() {
    if (!this.props.columns.length) {
      return <p>No columns specified.</p>;
    }

    return (
      <ReactTable
        data={this.state.tableData}
        defaultPageSize={this.props.defaultPageSize}
        className="-ui -striped -table"
        columns={this.props.columns}
        loading={this.state.loading}
        loadingText={`Loading ${this.props.entityName}`}
        minRows={5}
        multiSort={false}
        noDataText={`No ${this.props.entityName} Available`}
        rowsText={`${this.props.entityName}`}
      />
    );
  }
}

export default BasicTable;

BasicTable.defaultProps = {
  columns: [],
  defaultPageSize: 20,
  entityName: 'Rows',
  fetch: true,
  returnData: false,
  subComponent: null
};

BasicTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  entityName: PropTypes.string,
  fetch: PropTypes.bool,
  keyName: PropTypes.string,
  url: PropTypes.string
};
