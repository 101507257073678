import React from 'react';
import { Link } from 'react-router-dom';
import { TiEye } from 'react-icons/ti';

export default function TableViewButton(props) {
  return (
    <Link to={props.url}>
      <TiEye size={24} className="action-icon" />
    </Link>
  );
}
