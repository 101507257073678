import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { Provider } from "react-redux";

import { store } from "./duck";
import BdayvipApp from "./BdayvipApp";
import TagManager from 'react-gtm-module'

import "react-table/react-table.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./style/main.scss";

axios.defaults.baseURL = "/api/account/";

const tagManagerArgs = {
    gtmId: 'GTM-M9XGG3V'
}

TagManager.initialize(tagManagerArgs)

class ReactApp extends React.Component {
  state = {};

  render() {
    return (
      <Provider store={store}>
        <BdayvipApp style={{ height: "100%" }} />
      </Provider>
    );
  }
}

ReactDOM.render(
  <ReactApp style={{ height: "100%" }} />,
  document.getElementById("app")
);

if (module.hot) {
  module.hot.accept();
}
